































import Vue from 'vue';
import Component from 'vue-class-component';
import { AreasModule } from '@/store/areas/areas';
import { areasModule, areaTriggersModule } from '@/store';
import PageLayout from '@/components/page/layout.vue';
import TableGenerator, { ITableColumn } from '@/components/tables/generator.vue';
import { TableComponentNames } from '@/components/tables/components/names';
import TableNavigation from '@/components/tables/navigation.vue';
import AreaForm from '@/components/areas/areas/form/index.vue';

const schema: ITableColumn[] = [
  { label_i18n: 'id', prop: 'id', 'class-name': 'events-table__id-cell', width: 80 },
  { label_i18n: 'image', prop: 'cameras', 'class-name': 'cell_without_paddings', component: TableComponentNames.PolygonView, width: 160 },
  { label_i18n: 'name', prop: 'name', component: TableComponentNames.AreaNameAndDescription, 'min-width': 200 },
  { label_i18n: 'created', prop: 'created_date', component: TableComponentNames.DateTime, width: 160 }
];

@Component({
  components: { AreaForm, TableNavigation, PageLayout, TableGenerator }
})
export default class Areas extends Vue {
  get state(): AreasModule {
    return areasModule;
  }

  get schema(): ITableColumn[] {
    return schema;
  }

  mounted() {
    this.$store.dispatch('getAllCameras');
    this.state.setAutoUpdate(true);
  }

  beforeDestroy() {
    this.state.setAutoUpdate(false);
  }

  rowClickHandler({ item, column }) {
    const route = column.property === 'id' ? areasModule.getItemRoute(item) : areaTriggersModule.getListRoute({ area: [item.id] });
    this.$router.push(route);
  }

  createHandler() {
    this.$router.push(this.state.getNewItemRoute());
  }

  async deleteHandler() {
    for (let item of this.state.selectedItems) {
      try {
        await this.state.delete(item.id);
      } catch (e) {
        console.warn('delete.error ', e);
      }
    }
    await this.state.get();
  }
}
